<template>
    <div
        v-if="keepAlive || show"
        v-show="show"
        ref="swipeArea"
        class="modal-bg"
        :class="{
            [mode]: true,
            'modal--with-scroll': props.enableScroll,
            'has-mobile-drawer': props.mobileDrawer
        }"
        :style="props.showNavbar ? 'z-index: 1004;' : 'top: 0;'"
        @keyup.esc="emit('closed')"
        @click="handleBackgroundClick"
    >
        <div
            v-if="!isMobileDrawer"
            class="modal"
            :style="props.customStyles?.modal"
            :class="{
                [mode]: true,
                'modal--no-padding': noPadding,
                'modal--with-scroll': props.enableScroll,
                'no-mobile-drawer': !props.mobileDrawer,
                'has-mobile-drawer': props.mobileDrawer
            }"
            tabindex="-1"
            role="dialog"
        >
            <slot />
            <CloseSVG
                v-if="showCloseButton"
                class="modal__close"
                :style="props.customStyles?.modal__close"
                :class="{
                    [mode]: true
                }"
                @click="emit('closed')"
            />
        </div>
        <div
            v-if="isMobileDrawer"
            class="modal-mobile-drawer"
        >
            <mobile-drawer
                :open="true"
                :drawer-handle-visible="showCloseButton"
                :scrollable="props.enableScroll"
                @update:open="$emit('closed')"
            >
                <template #body>
                    <slot />
                </template>
            </mobile-drawer>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, PropType, StyleValue, computed, Ref, ref } from 'vue'
import CloseSVG from "@bx-icons/regular/bx-x.svg"
import MobileDrawer from '@components/Menus/MobileDrawer.vue'
import { isMobileReactive } from '@ts/Util/responsiveness'

export type CustomStyles = {
    modal?: StyleValue,
    modal__close?: StyleValue
}

const swipeArea: Ref<HTMLElement | null> = ref(null);

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    noPadding: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String,
        default: 'light',
        validator: (value: string) => {
            // You can add more modes types here
            return [
                'light',
                'dark',
                'youtube'
            ].includes(value)
        }
    },
    customStyles: {
        type: Object as PropType<CustomStyles>,
        default: () => { }
    },
    keepAlive: {
        type: Boolean,
        default: false
    },
    closeWhenTypeEsc: {
        type: Boolean,
        default: false
    },
    closeWhenClickOnBackground: {
        type: Boolean,
        default: true
    },
    showCloseButton: {
        type: Boolean,
        default: true
    },
    enableScroll: {
        type: Boolean,
        default: false
    },
    mobileDrawer: {
        type: Boolean,
        default: false
    },
    showNavbar: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['closed'])

const emitClosedOnYoutubeModeListener = (e) => {
    if (props.show && props.mode === 'youtube' && e.key === 'Escape' && props.closeWhenTypeEsc) {
        emit('closed')
    }
}

onMounted(() => {
    document.addEventListener('keyup', emitClosedOnYoutubeModeListener);
})
onUnmounted(() => document.removeEventListener('keyup', emitClosedOnYoutubeModeListener))

const handleBackgroundClick = (e) => {
    if (e.target?.classList.contains('modal-bg') && props.show && props.closeWhenClickOnBackground) {
        emit('closed')
    }
}
// this one is introduced to avoid double rendering of a modal content and causing error with PayPal buttons in the mobile drawer mode
const isMobileDrawer = computed((): boolean => {
    const isMobile: Ref<boolean> = isMobileReactive()
    return isMobile.value && props.mobileDrawer
})
</script>

<style scoped>
/* Common */
.modal-bg {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(44, 45, 55, .75);
    z-index: 2147484000;
    touch-action: none;
}

.modal-bg.modal--with-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    touch-action: manipulation;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border: 0.0625rem solid #CACACD;
    opacity: 1.0;
    width: 100%;
    padding: 1.75rem 1rem;
}

.modal.modal--with-scroll {
    top: 5%;
    transform: translateX(-50%);

}

.modal.modal--with-scroll.no-mobile-drawer {
    overflow: scroll;
    max-height: 80vh;
}

.modal__close {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    padding: 2.125rem 1.875rem;
    cursor: pointer;
}

/* Light mode */
.modal__close.light {
    fill: var(--zumba-gray-800);
}

.modal.modal--no-padding {
    padding: 0;
    border: none;
}

/* Dark mode */
.modal__close.dark {
    fill: var(--zumba-white);
    background-color: transparent;
}

.modal.dark {
    border: none;
    background-color: var(--zumba-gray-700);
}

/* Youtube mode */
.modal.youtube {
    width: 90%;
    height: 50vw;
}

.modal-bg.youtube {
    background: url('@images/youtube-modal-bg.png') repeat #1d1d24f0;
}

.modal__close.youtube {
    top: -0.95em;
    right: -0.95em;
    background: #d43361;
    text-align: center;
    border-radius: 50%;
    padding: .5em;
    width: .76em !important;
    height: .76em !important;
}

.modal.has-mobile-drawer {
    display: none;
}

@media all and (min-width: 48rem) {
    .modal {
        width: auto;
        padding: 1.75rem;
    }

    .modal.has-mobile-drawer {
        display: block;
    }

    .modal__close {
        padding: 1.75rem;
    }

    .modal.modal--no-padding {
        padding: 0;
        border: none;
    }

    .modal-mobile-drawer {
        display: none;
    }
}

@media all and (min-width: 90rem) {
    .modal.youtube {
        width: 67.75em;
        height: 38em;
    }
}

/* Strong */
</style>
