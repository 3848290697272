<template>
    <div class="action-item">
        <a
            :href="href"
            class="z-b4"
            v-bind="attributes"
            @click="handleClick"
        >
            <div class="action-icon-container">
                <component
                    :is="iconSvg"
                    v-if="iconSvg"
                    class="action-icon"
                />
            </div>
            <span
                class="z-b6 action-label"
                :class="{'f-inter': isZumbaThemed}"
            >
                {{ title }}
            </span>
        </a>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { useIconLoader } from '@ts/Navigation/useIconLoader';
import { theme, ThemeType } from "@ts/Util/theme";

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    href: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    attributes: {
        type: Object as PropType<Record<string, string>>,
        required: true
    }
});

const isZumbaThemed = theme.value === ThemeType.Zumba;

const emit = defineEmits(['clicked']);

const { iconSvg } = useIconLoader(props.icon);

const handleClick = () => {
    emit('clicked', props );
};

</script>
<style lang="scss" scoped>
    .action-item {
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 0.5rem;
            width: 4.4rem;

            .action-icon-container {
                width: 3.125rem;
                height: 3.125rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 200ms ease all;

                .action-icon {
                    width: 1.5rem;
                    height: 1.5rem;
                    :deep(path) {
                        fill: var(--zumba-white);
                        &[stroke] {
                            stroke: var(--zumba-white);
                        }
                    }
                }

                &:hover {
                    background-color: var(--zumba-dark-coral-dimmed);
                }
            }

            .action-label {
                color: var(--zumba-white);
                width: 5.5rem;
                text-overflow: ellipsis;
                text-wrap-mode: nowrap;
                overflow: hidden;
            }
        }
    }

    .theme-zumba .action-icon-container {
        background-color: var(--zumba-plum);
        border-radius: 0.625rem;
    }

    .theme-strong {
        .action-icon-container {
            background-color: var(--strong-primary-coral);

            &:hover {
                background-color: var(--strong-secondary-dark-coral);
            }
        }

        .action-label {
            font-size: 0.75rem;
        }
    }
</style>
