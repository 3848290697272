<template>
    <div
        class="tooltip-trigger"
        @mouseenter="() => handleVisibility('moveenter', true)"
        @mouseleave="() => handleVisibility('moveleave', false)"
    >
        <span
            v-if="$slots['trigger']"
        >
            <slot name="trigger" />
        </span>

        <div
            v-if="content.length > 0"
            class="tooltip-content"
            :class="[{ visible }]"
            :style="tooltipStyle"
        >
            <!-- eslint-disable vue/no-v-html-->
            <div
                class="z-b5"
                :class="{'f-inter': isZumbaThemed}"
                v-html="content"
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import {isMobileReactive} from '@ts/Util/responsiveness'
import { theme, ThemeType } from "@ts/Util/theme";

export default defineComponent({
    name: 'NavTooltip',
    props: {
        hasLink: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const visible = ref<boolean>(false)

        const isMobile = isMobileReactive();

        const isZumbaThemed = theme.value === ThemeType.Zumba;

        const tooltipStyle = computed(() => {

            let bottomOffset = 0;
            let leftOffset = 0;

            if (!isMobile.value) {
                leftOffset = 8;
                bottomOffset = props.content.length > 90 ? -3 : -2;
            } else {
                leftOffset = 11;
                bottomOffset = props.content.length > 90 ? -2.4 : -1.2;
            }
            return {
                bottom: `calc(var(--offset-distance) * ${bottomOffset})`,
                left: `${leftOffset}rem`,
            }
        })

        const handleVisibility = (e: String, visibility: boolean) => {
            if (!isMobile.value) {
                visible.value = visibility;
            } else if (isMobile.value) {
                visible.value = !visible.value;
            }
        }

        return {
            visible,
            tooltipStyle,
            isMobile,
            isZumbaThemed,
            handleVisibility,
        }
    },
})
</script>
<style scoped lang="scss">
.tooltip-trigger {
    position: relative;
    --tooltip-width: 10.375rem;
    --tooltip-width-wide: 13rem;
    --tooltip-triangle-size: .5rem;
    --tooltip-height: 2.5rem;
    --offset-distance: calc(var(--tooltip-triangle-size) * 2);
    display: inline-block;
    width: 100%;
}

.tooltip-content {
    position: absolute;
    background-color: var(--zumba-gray-800);
    color: var(--zumba-white);
    box-sizing: border-box;
    width: var(--tooltip-width);
    margin-left: calc(var(--tooltip-width) * -0.5);
    left: 54%;
    padding: 0.75rem;
    border-radius: .25rem;
    display: none;
    z-index: 9999;

    margin-left: auto;
    right: auto;
}

.tooltip-content::before {
    content: "";
    position: absolute;
    width: var(--tooltip-triangle-size);
    height: var(--tooltip-triangle-size);
    left: 50%;
    top: 100%;
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    background-color: var(--zumba-gray-800);

    left: 0;
    top: 50%;
}

.tooltip-content.visible {
    display: block;
}

.theme-zumba .tooltip-content {
    :deep(.tooltip-link) {
        color: var(--zumba-neon-green);
    }
}

.theme-strong .tooltip-content {
    :deep(.tooltip-link) {
        color: var(--strong-secondary-light-orange);
    }
}
</style>
