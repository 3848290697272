<template>
    <div
        v-if="recentActivity.length > 0"
        class="nav-recent-activity"
        :class="{ 'mobile': isMobile }"
    >
        <span
            class="z-b6 recents"
            :class="{ 'f-inter': isZumbaThemed }"
        >
            {{ t.recents }}
        </span>
        <div class="recents-container">
            <template
                v-for="item in recentActivity"
                :key="item.id"
            >
                <NavRecentActivityItem
                    :id="item.id"
                    :icon="item.icon"
                    :title="item.title"
                    :href="item.href"
                    :locked="item.locked"
                    :attributes="item.attributes"
                    @clicked="(link) => emit('clicked', link)"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, computed, onMounted } from 'vue';
import { validator } from "@ts/Util/i18n";
import NavRecentActivityItem from '@components/Navigation/NavRecentActivityItem.vue'
import { theme, ThemeType } from "@ts/Util/theme";
import { RecentActivityLink, saveDefaultActivity } from '@ts/Navigation/recent-activity';
import { isMobile } from '@ts/Util/responsiveness';

const localT = {
    recents: 'Recents'
};
</script>

<script setup lang="ts">
const props = defineProps({
    t: {
        type: Object as PropType<typeof localT>,
        default: () => localT,
        validator: validator(localT)
    },
    items: {
        type: Array as PropType<RecentActivityLink[]>,
        default: () => []
    },
    defaultItems: {
        type: Array as PropType<RecentActivityLink[]>,
        required: true
    }
});

const isZumbaThemed = theme.value === ThemeType.Zumba;
const emit = defineEmits(['clicked']);

onMounted(() => {
    if (props.items.length === 0) {
        saveDefaultActivity(props.defaultItems);
    }
});
const recentActivity = computed(() => props.items.length > 0 ? props.items : props.defaultItems);

</script>
<style lang="scss" scoped>
.nav-recent-activity {
    padding: 0.75rem 1.5rem 1rem 1.5rem;

    span {
        color: var(--zumba-white);
    }
}

.recents-container {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-top: 0.75rem;
}

.recents {
    font-weight: 700;
}

.theme-zumba {
    .nav-recent-activity {
        background-color: var(--zumba-gray-800);
        border-top: 1px solid var(--zumba-gray-600);

        &.mobile {
            border-bottom: 1px solid var(--zumba-gray-600);
        }

        .recents {
            color: var(--zumba-gray-300);
        }
    }
}

.theme-strong {
    .nav-recent-activity {
        background-color: var(--strong-secondary-dark-gray);
        border-top: 1px solid var(--strong-neutral-gray-600);

        &.mobile {
            border-bottom: 1px solid var(--strong-neutral-gray-600);
        }

        .recents {
            color: var(--strong-neutral-gray-300);
            font-size: 0.75rem;
        }
    }
}

</style>
