<template>
    <div
        :class="{ 'drawer-bg' : backgroundOverlay, 'open' : open }"
        @click="handleBackgroundClick"
    >
        <div
            class="chest"
            :style="`height: ${chestHeight}`"
            :class="{
                'chest--handle-hidden' : !drawerHandleVisible
            }"
        >
            <div
                :class="classes"
            >
                <div
                    ref="swipeArea"
                    href="#"
                    class="toggle-container"
                    @click.prevent="toggleDrawer"
                >
                    <a class="toggle">
                        <slot name="toggle-header" />
                    </a>
                </div>
                <div
                    v-if="title || subhead"
                    class="drawer-header"
                    :class="{'center-aligned': alignCenter}"
                >
                    <slot name="header-action" />
                    <h5
                        v-if="title"
                        class="drawer-title"
                    >
                        {{ title }}
                    </h5>
                    <p
                        v-if="subhead"
                        class="p-caption text-light-secondary drawer-subhead"
                    >
                        {{ subhead }}
                    </p>
                </div>

                <div :class="{ 'scrollable' : scrollable }">
                    <slot name="body" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue'
import { SwipeDirection, useSwipe } from '@vueuse/core'

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    title: {
        type: String,
        default: '',
    },
    subhead: {
        type: String,
        default: '',
    },
    drawerHandleVisible: {
        type: Boolean,
        default: true
    },
    scrollable: {
        type: Boolean,
        required: false,
    },
    backgroundOverlay: {
        type: Boolean,
        required: false,
    },
    closeWhenClickOnBackground: {
        type: Boolean,
        default: false
    },
    alignCenter: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:open'])
const slots = useSlots()

const classes = computed(() =>  {
    return {
        'drawer': true,
        'open': props.open,
        'drawer--no-handle': !props.drawerHandleVisible,
    };
})

const swipeArea = ref(null)

useSwipe(swipeArea, {
    threshold: 30,
    onSwipeEnd(e: TouchEvent, direction: SwipeDirection) {
        if(direction === SwipeDirection.DOWN) {
            emit('update:open', false);
        }
    }
})

const chestHeight = computed((): string => {
    return !slots['toggle-header'] ? '2rem' : '3.4rem'
})

const toggleDrawer = ()=> {
    emit('update:open', !props.open);
}
const handleBackgroundClick = (e) => {
    if (e.target?.classList.contains('drawer-bg') && props.open && props.closeWhenClickOnBackground) {
        toggleDrawer()
    }
}
</script>

<style scoped>
.open.drawer-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(44,45,55, .75);
    z-index: 1000000;
    touch-action: none;
}
.chest {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2rem;
    z-index: 1000000;
}

.chest.chest--handle-hidden {
    bottom: -5rem;
}

.drawer {
    font-weight: normal;
    border: thin solid var(--zumba-gray-200);
    padding: 0 1.5rem 1.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-size: 1rem;
    transform: translateY(0%);
    transition: transform 0.3s ease-in-out;
    background-color: var(--zumba-white);
    z-index: 999999;
    max-height: 85vh;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

 .drawer::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.drawer.drawer--no-handle:not(.open) {
    transform: translateY(5rem);
}

.drawer.open {
    transform: translateY(calc(-100% + 2rem));
}

.chest.chest--handle-hidden .drawer.open {
    transform: translateY(calc(-100% - 3rem));
}

.drawer.open .scrollable {
    overflow-y: scroll;
    max-height: 80vh;
}

.toggle {
    display: block;
    position: relative;
    min-height: 2.5rem;
    margin-top:.8rem;
    touch-action: none;
}

.toggle::before {
    content: '';
    display: block;
    position: absolute;
    height: .25rem;
    width: 4rem;
    border-radius: .125rem;
    left:50%;
    top:0;
    margin-left: -2rem;
    background-color: var(--zumba-gray-300);
}

.drawer-header {
    margin-bottom: .5rem;
}

.drawer-header.center-aligned {
    text-align: center;
}

.drawer-subhead {
    margin: 1rem 0 0 0;
}

.reset_all_filters {
    margin-top: -1.5rem;
    float: right;
}
</style>
